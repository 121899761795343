<template lang="pug">
  div
    om-dropdown-input(:label="getFitLabel" :property="getPath('smartSize.type')" :items="size" :typeOfComponent="typeOfComponent")
    om-range-input(v-if="isManual" :label="getWidthLabel" :property="getPath('smartSize.width')" :min="30", :max="600", :step="1" :typeOfComponent="typeOfComponent")
    om-range-input.mt-3(v-if="needHeight" label="height" :property="getPath('height')" :min="30", :max="300", :step="1" :typeOfComponent="typeOfComponent")
</template>

<script>
  import { mapGetters } from 'vuex';
  import RadioStyleChooser from '@/editor/components/RadioStyleChooser.vue';
  import itemMixin from '../../../mixins/item';

  export default {
    components: { RadioStyleChooser },
    mixins: [itemMixin],
    props: {
      propsProperty: { type: String },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
      needHeight: { type: Boolean, default: true },
    },
    // watch: {
    //   sizeValue (n, o) {
    //     if (n !== 'manual') {
    //       this.setValueOf(this.getPath('width'), null)
    //     }
    //   }
    // },
    computed: {
      ...mapGetters(['isNano', 'nestedAccess']),
      isManual() {
        return this.sizeValue === 'manual';
      },
      sizeValue() {
        return this.getValueOf(this.getPath('smartSize.type'));
      },
      size() {
        const array = [];

        if (
          this.typeOfComponent === 'button' ||
          this.subPath === 'button' ||
          this.typeOfComponent === 'product'
        )
          array.push({ key: 'fluid', value: 'fluid' });
        array.push({ key: 'manual', value: 'manual' });

        if (!this.isNano) {
          const keyValue = this.typeOfComponent === 'button' ? 'simple100' : 'w100';
          array.push({ key: keyValue, value: '100w' });
        }
        return array;
      },
      getFitLabel() {
        return this.typeOfComponent === 'button' || this.typeOfComponent === 'inputs'
          ? 'width'
          : 'fit';
      },
      getWidthLabel() {
        return this.typeOfComponent === 'button' || this.typeOfComponent === 'inputs'
          ? ''
          : 'width';
      },
    },
  };
</script>
